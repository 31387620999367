<script lang="ts" setup>
import OverlayBase from '@/components/overlays/OverlayBase.vue'
import ResponsiveImage from '@/components/ResponsiveImage.vue'
import TwoColsBlock from '@/components/layout/TwoColsBlock.vue'
import SkrHeading from '@/components/SkrHeading.vue'
import SkrButton from '@/components/button/SkrButton.vue'

const { isLinkFlow, lockedMinutes = 0 } = defineProps<{
  isLinkFlow: boolean
  lockedMinutes: number
}>()

const emit = defineEmits<{
  (e: 'close'): void
}>()

const { t } = useI18n()

const info = computed<{ title: string; subtitle: string }>(() => {
  if (isLinkFlow) {
    return {
      title: t('viewer.tan.locked.title'),
      subtitle: t('viewer.tan.locked.link_flow.text'),
    }
  } else {
    return {
      title: t('viewer.tan.locked.title'),
      subtitle: t('viewer.tan.locked.tan_flow.text'),
    }
  }
})
</script>

<template>
  <overlay-base :transition="false">
    <two-cols-block split="5/7" class="fill-height">
      <template #left>
        <div class="mb-8">
          <skr-heading level="1" semantic>{{ info.title }}</skr-heading>
          <skr-heading level="2">{{ info.subtitle }}</skr-heading>
        </div>
        <p class="mb-8">
          <strong>{{ t('viewer.tan.locked.remaining_title') }}</strong>
          {{ ' ' }}
          <span>{{ lockedMinutes }} {{ t('viewer.tan.locked.remaining_minutes') }}</span>
        </p>
        <skr-button v-if="!isLinkFlow" size="lg" block @click="emit('close')">{{ t('global.back') }}</skr-button>
      </template>
      <template #right>
        <responsive-image source="tan-locked" :width="437" :height="443" />
      </template>
    </two-cols-block>
  </overlay-base>
</template>
